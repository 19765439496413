<template>
  <div class="px-7 py-7">
    <div v-if="loadTables">
      <checklist-table></checklist-table>
      <br /><br />
    </div>
    <ticket-info-table @reload="getShowTableStatus"></ticket-info-table>
    <div v-if="loadTables">
      <game-graph-table></game-graph-table>
    </div>
    <br /><br />
    <!--  <div v-if="loadTables">
      <br /><br />
      <game-overview></game-overview>
    </div>-->
    <div v-if="loadTables">
      <vash-tablen></vash-tablen>
    </div>
    <br />
    <br />
    <div v-if="loadTables">
      <vash-table></vash-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashboard",
  components: {
    TicketInfoTable: () => import("../TicketInfo/TicketInfoTable.vue"),
    VashTable: () => import("../GameDashboardVASH/GameDashboardVASH"),
    VashTablen: () => import("../GameDashboardVASH/GameDashboardVASHN"),
    ChecklistTable: () => import("../Checklist/Checklist"),
    GameGraphTable: () => import("../Gamegraph/Gamegraph"),
    //  GameOverview: () => import("../GameOverview/GameOverview"),
  },
  data() {
    return {
      loadTables: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    getShowTableStatus() {
      this.loadTables = true;
    },
  },
  mounted() {},
};
</script>
