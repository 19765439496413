<template>
  <div class="px-7 py-8">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-row>
            <v-col
              cols="6"
              class="px-10 py-5"
              style="border-right: 1px solid #ffba00"
            >
              <v-row no-gutters>
                <v-col cols="12" class="py-4">
                  <div
                    class="fieldHeading px-1"
                    style="
                      border-bottom: 2px solid #2c1963;
                      color: #2c1963;
                      font-weight: 600;
                    "
                  >
                    Audience
                  </div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <div style="font-weight: 600; color: black; font-size: 16px">
                    <span v-if="Object.keys(schoolDetail).length !== 0"
                      >{{ schoolDetail.school_name }},</span
                    ><span v-if="Object.keys(districtDetail).length !== 0">
                      {{ districtDetail.district_name }}</span
                    >
                  </div>
                  <div
                    style="font-weight: 400; font-size: 16px; color: black"
                    v-if="Object.keys(campaignDetail).length !== 0"
                  >
                    {{ campaignDetail.campaign_id }}
                  </div>
                  <div style="font-weight: 400; font-size: 16px">
                    send to
                    <span
                      style="color: black; font-weight: 600"
                      v-if="Object.keys(audienceDetail).length !== 0"
                    >
                      {{ audienceDetail.text }}</span
                    >
                  </div>
                  <div
                    style="position: relative; bottom: 60%; float: right"
                    v-if="type === 'add'"
                  >
                    <v-chip
                      class="actionColumn-btn py-0"
                      @click="toggleGameAudienceModal({ show: true })"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="icons"
                            color="#6B6B6B"
                            size="22"
                          >
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-chip>
                  </div>
                </v-col>
                <!-- <v-col cols="12" class="pb-4 pt-0">
                  <div
                    class="fieldHeading px-1"
                    style="
                      border-bottom: 2px solid #2c1963;
                      color: #2c1963;
                      width: 19%;
                      font-weight: 600;
                    "
                  >
                    Select template
                  </div>
                  <v-divider></v-divider>
                </v-col> -->
                <!-- <v-col cols="12">
                  <v-autocomplete
                    placeholder="Template"
                    outlined
                    :readonly="type==='view'"
                    :items="manualTemplateList"
                    item-text="email_title"
                    item-value="id"
                    dense
                    class="formFields"
                    v-model="selectTemplate"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col> -->
                <v-col cols="12" class="pb-0 mt-4">
                  <div class="fieldHeading px-1">Email Subject</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    placeholder="Email Subject"
                    :readonly="type === 'view'"
                    outlined
                    dense
                    class="formFields"
                    v-model="emailSubject"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    class="radio-group py-0 ma-0 px-1"
                    small
                    v-model="privacyStatus"
                    row
                  >
                    <v-radio
                      :disabled="formLoading"
                      color="#2C1963"
                      label="Editor"
                      value="editor"
                    ></v-radio>
                    <v-radio
                      :disabled="formLoading"
                      color="#2C1963"
                      label="HTML"
                      value="textarea"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <div class="fieldHeading px-1">Email Body</div>
                </v-col>
                <v-col cols="12" v-if="privacyStatus == 'textarea'">
                  <div>
                    <v-textarea
                      outlined
                      dense
                      v-model="emailBody"
                      color="#7253CF"
                      class="formFields"
                      rows="12"
                      :disabled="formLoading"
                    >
                    </v-textarea>
                  </div>
                </v-col>
                <v-col cols="12" v-if="privacyStatus == 'editor'">
                  <div style="width: 100%; height: 370px">
                    <quillEditor v-model="emailBody" :options="editorOption" />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-end"
                  v-if="type === 'add'"
                >
                  <v-btn
                    dark
                    height="45"
                    width="180"
                    color="#2c1963"
                    class="text-capitalize"
                    style="border-radius: 10px"
                    :loading="formLoading"
                    @click="sendEmail"
                  >
                    <span>Send Email</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="6"
              class="px-10 py-5"
              style="background-color: #fffcf5"
            >
              <v-row>
                <v-col cols="12">
                  <div class="keywordHeading">Available Keywords</div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <div class="keywordDescription">
                    There are the available keywords to be used in this email
                    templates
                  </div>
                </v-col>
              </v-row>
              <v-row v-for="(item, i) in keywordsList" :key="i">
                <v-col cols="5">
                  <div class="keywordTitle px-4 py-2">{{ item.title }}</div>
                </v-col>
                <v-col cols="1">
                  <div style="font-size: 19px; color: black" class="py-2">
                    :
                  </div>
                </v-col>
                <v-col cols="5">
                  <div class="keyword py-2 px-4">{{ item.key }}</div>
                </v-col>
              </v-row>
              <br /><br />
              <hr style="border-top: 1px solid #000000" />
              <h4>Preview Of HTML:</h4>
              <div v-html="emailBody" v-if="privacyStatus == 'textarea'"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <AudienceModal @reload="loadFormData" />
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_EMAIL_GET_KEYWORDS,
  API_BULK_GAME_EMAIL_POST,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "BulkEmailForm",
  components: {
    quillEditor,
    AudienceModal: () =>
      import("../BulkEmailAudienceModal/BulkEmailAudienceGameModal"),
  },
  data() {
    return {
      id: null,
      rules: RULES,
      loading: false,
      type: "",
      formLoading: false,
      school: "",
      district: "",
      campaign: "",
      date: "",
      time: "",
      emailSubject: "",
      emailDescription: "",
      selectTemplate: null,
      privacyStatus: "editor",
      keywordsList: [],
      emailBody: "",
      editorOption: {
        placeholder: "Email body",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }],
            ["clean"],
          ],
        },
      },
      audienceList: [],
      audienceDetail: {},
      districtDetail: {},
      schoolDetail: {},
      campaignDetail: {},
      details: {},
      gameId: localStorage.getItem("gameID"),
    };
  },
  computed: {
    ...mapGetters({
      getEmailDetail: "emailSettings/getComposeEmailDetail",
    }),
    toast() {
      let message = "";
      if (this.type === "add") {
        message = "Email Template Added";
      } else {
        message = "Email Template Updated";
      }
      return message;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      toggleGameAudienceModal: "emailSettings/toggleModal",
    }),
    loadFormData() {
      // alert("fsadsadad")
      this.loading = true;
      this.audienceDetail = this.getEmailDetail.audience;
      console.log("******", this.audienceDetail);
      this.gameDetail = this.getEmailDetail.game;
      if (this.type === "view") {
        this.emailSubject = this.getEmailDetail.email.subject;
        this.emailBody = this.getEmailDetail.email.body;
      }
      this.getKeyWords();
    },
    getKeyWords() {
      const successHandler = (res) => {
        console.log(res.data);
        this.keywordsList = res.data.email_template_keyword_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        API_EMAIL_GET_KEYWORDS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    sendEmail() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.showToast({
          message: "Email sent successfully."
,
          color: "s",
        });
        this.$router.go(-1);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      formData["game"] = this.gameId;
      let sent_to = [];
      sent_to.push(this.audienceDetail.value);
      formData["sender"] = "admin";
      formData["send_to"] = sent_to;
      formData["email_subject"] = this.emailSubject;
      formData["email_body"] = this.emailBody;
      Axios.request_POST(
        API_BULK_GAME_EMAIL_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
  },
  mounted() {
    this.loading = true;
    this.type = this.$route.query["type"];
    this.loadFormData();
  },
};
</script>
<style scoped>
.fieldHeading {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.quill-editor {
  height: 300px;
  font-family: Lato;
}
.v-btn >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  /* or 29px */
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 17px;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 35px;
  width: 37px;
  cursor: pointer;
  border-radius: 4px;
}
.keywordHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  color: #2c1963;
  border-bottom: 4px solid #2c1963;
  width: 60%;
}
.keywordDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
}
.keywordTitle {
  color: #000000;
  border: 2px dashed #2c1963;
  font-size: 17px;
  border-radius: 5px;
}
.keyword {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* or 310% */

  color: #2c1963;
}
</style>
